import React, { useState } from "react";
import { InputSearch, ResultWeather } from "../../Components";

export default function Home() {
  const [query, setQuery] = useState("");
  const [weather, setWeather] = useState({});

  return (
    <>
      <InputSearch query={query} setQuery={setQuery} setWeather={setWeather} />
      <ResultWeather weather={weather} />
    </>
  );
}
