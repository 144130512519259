import "./App.scss";
import { Home } from "./Pages";

function App() {
  return (
    <div className="main-container">
      <Home />
    </div>
  );
}

export default App;
