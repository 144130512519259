import React from "react";
import { fetchWeather } from "../../Api";

export default function InputSearch({ query, setQuery, setWeather }) {
  const search = async (e) => {
    if (e.key === "Enter") {
      const data = await fetchWeather(query);
      setWeather(data);
      setQuery("");
    }
  };

  return (
    <>
      <h2 className="tittle">Cuaca Sekarang?</h2>
      <input
        type="text"
        className="search"
        placeholder="Cari lokasi ..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={search}
      />
    </>
  );
}
